import React from "react";
import WoundDashView from "./WoundDashView";
// import { getVisitData, selectDataState } from "redux/dataSlice";
import {
  selectWoundDashState,
  fetchWoundDataPoint,
  fetchWoundMetrics,
  fetchImageAndURL,
  refreshData,
  setPID,
  fetchLImageAndURL,
  createThumbnail,
} from "redux/woundDashSlice";
import { selectDashboardState } from "redux/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { firebaseGenerateDownloadURL, firebaseGetImagePath } from "services/firebase/firebaseStorage";
import { useState } from "react";

export const WoundDashContainer = (props) => {
  const { woundDataPoint, woundMetrics, thumbnail, data, url } = useSelector(selectWoundDashState);
  const { PID, tabs } = useSelector(selectDashboardState);
  const [urls, setUrls] = useState(null)
  console.log(tabs)
  const lVisit = tabs.at(-1).split("/").at(1);
  const dispatch = useDispatch();
  const thumbArr = []
  useEffect(() => {
    if (!data[PID] || !data[PID].woundDataPoint) {
      dispatch(fetchWoundDataPoint({ PID }));
    }
    if (!data[PID] || !data[PID].woundMetrics) {
      dispatch(fetchWoundMetrics({ PID }));
    }
    if(data[PID] && data[PID].woundMetrics){
      console.log(data)
      if (!data[PID].imageWL) {
        dispatch(fetchImageAndURL({ PID, VID: 1, searchStr: "WHITE_NOFILTER" , dateString : getDate(data[PID].woundMetrics[1].date)}));
      }
      if (( !data[PID].imageWLL) && lVisit !== 1) {
        dispatch(
          fetchLImageAndURL({ PID, VID: lVisit, searchStr: "WHITE_NOFILTER", dateString : getDate(data[PID].woundMetrics[lVisit].date) })
        );
      }
    }
    
  }, [PID, data[PID], (data[PID] && data[PID].imageWL)]);
  
  console.log("areaList", lVisit);
  
  console.log(data, PID)

  const getDate = (string) =>{ //10062023160035
      console.log(string)
      console.log(string ? `${string.slice(4,8)}_${string.slice(2,4)}_${string.slice(0,2)}` : null)
      return string ? `${string.slice(4,8)}_${string.slice(2,4)}_${string.slice(0,2)}` : null
  }

  useEffect(()=>{ for(let i = 1; i < tabs.length; i++){
    console.log(data)
    firebaseGetImagePath(PID, tabs[i].split("/").at(-1), "WHITE_NOFILTER", (data[PID] && data[PID].woundMetrics) ? getDate(data[PID].woundMetrics[tabs[i].split("/").at(-1)]["date"]) : null).then(path=>{
      firebaseGenerateDownloadURL(path).then(url=>{
        thumbArr[tabs[i].split("/").at(-1)] = url
        setUrls(thumbArr)
        console.log(url)
      }).catch(err=>{
        console.error(err)
      })
    }).catch(err=>{
      console.error(err)
    }) 
    }},[data[PID]])

console.log(urls)


  return PID !== null && data[PID] && data[PID].woundMetrics ? (
    <WoundDashView
      PID={PID}
      woundData={data[PID].woundDataPoint}
      woundMetrics={data[PID].woundMetrics}
      wlURL={data[PID].imageWL}
      wllURL={data[PID].imageWLL}
      showLImg={lVisit != 1}
      urls= {urls}
    />
  ) : (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight:"90vh",
    }} >
      <h1>No Metrics Data found !</h1>
    </div>
  );
};
