import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataStore from "services/facade/dataStore";
import { firebaseGetAllWoundDataPoint, firebaseGetPatientListFromStorage } from "services/firebase/firebaseStorage";
import dashboardSlice from "./dashboardSlice";

const initialState = {
  mainDashData: {
    value: null,
    pData: null,
    vPoints: null,
    isError: false,
    isFetching: false,
  },
  woundMetrics: null,

  processedDashData: {},
};

const mDataStore = new DataStore();

export const getVisitDataPoints = createAsyncThunk(
  "data/get-visit-data-points",
  () => {
    return new Promise((resolve, reject) => {
      mDataStore.fetchVisitDataPoints().then((data) => {
        resolve(data);
      });
    });
  }
);

export const getAllWoundMetrics = createAsyncThunk(
  "data/get-all-wound-metrics",
  () => {
    return new Promise((resolve, reject) => {
      mDataStore.getAllWoundMetrics().then((data) => {
        firebaseGetPatientListFromStorage().then((listFromStorage)=>{
          let d = {};
           listFromStorage.prefixes.forEach(path =>{
             let dd = path._location.path.split("/").at(-1)
              if(Object.keys(data).includes(dd)){
                 d[dd] = data[dd]
              }
           }) 
        resolve(d);
          console.log(d);
            // console.log(listFromStorage)
        })
        
      });
    });
  }
);

const mainDashSlice = createSlice({
  name: "mainDashSlice",
  initialState,
  reducres: {
    onDataChange: (state, { payload }) => {
      state.mainDashData.isFetching = false;
      state.mainDashData.isError = false;
      state.mainDashData.value = payload;


      // totalImagingSessions: null,
      // totalUniquePatients: null,
      // totalRecurringPatients: null,

    },
  },
  extraReducers: {
    [getVisitDataPoints.pending]: (state) => {
      state.mainDashData.isFetching = true;
      state.mainDashData.isError = false;
    },
    [getAllWoundMetrics.fulfilled]: (state, { payload }) => {

      state.mainDashData.value = payload;
       
      console.log(payload)

      state.woundMetrics = payload;
    },
    [getVisitDataPoints.fulfilled]: (state, { payload }) => {
      state.mainDashData.isFetching = false;
      state.mainDashData.isError = false;
      state.mainDashData.vPoints = payload;

      console.log(payload)

      // totalImagingSessions: null,
      // totalUniquePatients: null,
      // totalRecurringPatients: null,

      const patients = Object.keys(payload);
      const processedData = {};

      console.log(patients)

      patients.forEach((patient) => {
        const visits = Object.keys(payload[patient]);
        visits.forEach((visit) => {
          if (visit !== 0) {
            const date = payload[patient][visit]["date"].slice(0, 8);
            if (date == 0) {
              console.log(patient, visit);
            }
            processedData[date] = [0, 0, 0];
          }
        });
      });

      patients.forEach((patient) => {
        console.log(payload)
        const visits = Object.keys(payload[patient]);
        if(Array.isArray(payload[patient])){
        visits.forEach((visit) => {
          if (visit !== 0) {
            const date = payload[patient][visit]["date"].slice(0, 8);
            processedData[date][0] += 1;
          }
        });
        // payload[patient] = payload[patient].filter(n=>n)
        console.log(payload[patient], patient);
        
        // const date =  payload[patient]["1"]? payload[patient]["1"]["date"].slice(0, 8): payload[patient]["2"]? payload[patient]["2"]["date"].slice(0, 8) : payload[patient]["3"]["date"].slice(0, 8) ;
        console.log(patient, Object.values(payload[patient])[0] )
        const date = Object.values(payload[patient])[0]["date"].slice(0, 8)
        console.log(date, patient)
        if (visits.length === 2 && !!date) {
          processedData[date][2] += 1;
        } else {
          processedData[date][1] += 1;
        }
      }else{console.log(visits)}
      });
      console.log(processedData);
      state.processedDashData = processedData;
      // let pidByDate = {}

      // Object.keys(payload).forEach(pid=>{
      //   for(let i = 1; i < Object.keys(payload[pid]).length; i++){
      //     pidByDate[payload[pid][i]["date"]] = pid
      //   }
      // })
      // state.vData = pidByDate
    },
    [getVisitDataPoints.rejected]: (state) => {
      state.mainDashData.isError = true;
      state.mainDashData.isFetching = false;
    },
  },
});

export const selectMainDashState = (state) => state.mainDashState;

export const { onDataChange } = mainDashSlice.actions;

export default mainDashSlice.reducer;
