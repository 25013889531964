import React from "react";
import Loader from "components/loader/loader";
import styled from "styled-components";

const SplashView = () => {
  return (
    <Wrapper>
      {/* <Image>
        <img
          // className="animation__shake"
          src="dist/img/AdiuvoRing.png"
          alt=""
          height={100}
          width={100}
        />
        
      </Image> */}
      <Loader width={200} height={200} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Image = styled.div`
  position: absolute;
  top: 256px;
  img {
    margin: 8px 0 0 0;
  }
  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default SplashView;
