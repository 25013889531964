import React from 'react'
import Lottie from 'react-lottie'
import data from './adiuvoLoader.json'

function Loader({width = 400 , height = 400, style }) {
    const options = {
        loop: true,
        autoplay: true, 
        animationData : data,
        
        
      };
  return (
    <Lottie
        options={options}
        height={width}
        width={height}
        isClickToPauseDisabled = {true}
        style={{
           cursor : 'unset',
           ...style    
        }}
    />
  )
}

export default Loader