import React from "react";
import { useState } from "react"

const { TextField, styled } = require("@mui/material")

const VisitBar = (props) =>{
  
    const Input = styled(TextField)({
        'input' :{
          backgroundColor: 'white',
          borderColor: 'white',
          color: '#252525',
          zIndex:"1",
          borderRadius:"25px",
          height: "10px"
        },
        
        '& label.Mui-focused': {
          color: "#252525",
          backgroundColor: 'white',
          outline: 'none',
          display:'none'
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
          
          '&.Mui-focused fieldset': {
            borderColor: 'white',
            outline:"none",
            backgroundColor:"white"
          },
          '&:hover fieldset':{
            borderColor:"white"
    
          },
          ' fieldset':{
            borderRadius:"25px",
            borderColor:"white",
            backgroundColor:"white",
            color:"#252525",
            zIndex:"0"
          }
        },
      });
    return <div>
        <Input
            value={props.visit === 0 ? "Overview" : props.visit }
            type = "number"
            sx={{
                height: "50px",
                width: "100px",
                padding : "1px"
            }}
            autoFocus
            placeholder = "Visit No."
            onChange={props.handlevisit}
        />
    </div>
}

export default VisitBar