import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectVisitDashState } from "redux/visitDashSlice";
import { selectWoundDashState } from "redux/woundDashSlice";
import { useEffect, useState } from "react";
import { firebaseGenerateDownloadURL, firebaseGetPrintParameters, firebaseGetUserConfig, getUID } from "services/firebase/firebaseStorage";
import { FormControlLabel, Switch } from "@mui/material";
// import {selectVisitDa}

const Report = (props) => {
  const visitDashState = useSelector(selectVisitDashState);
  const {  data } = useSelector(selectWoundDashState);
  const date = visitDashState.data[props.PID + "/" + props.VID]["visitDataPoint"]["date"];
  let woundType = data[props.PID]["woundDataPoint"].woundType;
  let patientName = data[props.PID]["woundDataPoint"].name;
  let gramType=props.woundMetrics && props["woundMetrics"][props.VID]["gramType"]
  const [ hospitalDetails, setHospitalDetails] = useState({hospitalName : "", hospitalLogoUrl : null})
  const [hospitalLogo, setHospitalLogo] = useState(null)
  const [printParameters,  setPrintParameters] = useState({})

  const [showHLogo, setHLogo] = useState(true)
  const [showHName, setHName] = useState(true)

  useEffect(()=>{

    firebaseGetPrintParameters().then(pp=>{
      console.log(pp)
      setPrintParameters(pp)
    }).catch(e=>{
      console.error(e)
    })
    
    firebaseGetUserConfig().then(userConfig=>{
      console.log(userConfig)
      setHospitalDetails({hospitalName: userConfig.hospitalName})
    })
  },[])

  useEffect(()=>{
    firebaseGenerateDownloadURL(`hospitals/${getUID()}/profiles/hospitalLogo`).then(url=>{
      setHospitalLogo(url)
    })
  },[])

    
  return (
  <>
    <FormControlLabel
      label="Show Hospital Logo"
          control={
            <Switch checked={showHLogo} onChange={()=>{setHLogo(!showHLogo)}} name="show Hospital logo" />
          }
          labelPlacement="start"
          
        />
        <FormControlLabel
      label="Show Hospital Name"
          control={
            <Switch checked={showHName} onChange={()=>{setHName(!showHName)}} name="show Hospital Name" />
          }
          labelPlacement="start"
          
        />
    <div id="report" style={{
      display: "flex",
      justifyContent:"center",
      alignItems:"center",
      width: "100%"
    }}>
    
     <div id="report-main">
    
       <Container >
           <img alt="report_bg" src="dist/img/report2.png"></img>
          {showHName && <span contentEditable id="hName"> {hospitalDetails.hospitalName}</span>}
           <span id="pid">{props.PID}</span>
           {printParameters && <span contentEditable id = "physicianName" >{printParameters.SF_PHYSICIAN === "" ? "UNKNOWN" : printParameters.SF_PHYSICIAN}</span>}
           {printParameters && <span contentEditable id = "sf_facility" >{printParameters.SF_FACILITY === "" ? "UNKNOWN" : printParameters.SF_FACILITY}</span>}
          { showHLogo && hospitalLogo && <img id="hLogo" src={hospitalLogo} alt="hospital Logo" />}
           <span id="date">
             {date.slice(0, 2) + "-" + date.slice(2, 4) + "-" + date.slice(4, 8)}
           </span>
           <span id="visit">{props.VID}</span>
           <span id="patientName" contentEditable >{patientName || " UNKNOWN "}</span>
           <span id="woundType" contentEditable >{woundType}</span>
           <span id="WoundLocation" contentEditable>{props["woundDataPoint"]["woundLocation"]}</span>
           <img id="image" src={props.wlURL} alt="" />
           <img id="imageb" src={props.fwlURL} alt="" />
           <img id="imagec" src={props.gcURL} alt="" />
           <i id="laoder" className="fas fa-spinner fa-spin"></i>
           <span id="gram" style={{color: `${gramType === "GN" ? 'green' : gramType === "GP" ? 'red' : '#002a54'}`}} >{gramType}</span>
           <span id="len">{props.woundMetrics && props["woundMetrics"][props.VID]["woundLength"]}</span>
           <span id="wid">{props.woundMetrics && props["woundMetrics"][props.VID]["woundWidth"]}</span>
    
           <span id="area">
             {props.woundMetrics && (
               props["woundMetrics"][props.VID]["woundLength"] *
               props["woundMetrics"][props.VID]["woundWidth"]
             ).toFixed(2)}
           </span>
         </Container>
     </div>
    </div>
  </>
  );
};





const Container1 = styled.div`
  position: relative;
  img {
    height: 1080px ;
  }
  #gram {
    position: absolute;
    top: 725px;
    left: 420px;
    font-weight: 500;
  }
  #len {
    position: absolute;
    top: 801px;
    left: 420px;
    font-weight: 500;
  }
  #wid {
    position: absolute;
    top: 840px;
    left: 420px;
    font-weight: 500;
  }
  #area {
    position: absolute;
    top: 877px;
    left: 420px;
    font-weight: 500;
  }
  #image {
    position: absolute;

    height: 350px;
    top: 240px;
    left: 45px;
  }
  #laoder {
    position: absolute;
    top: 380px;
    left: 385px;
  }
  #imageb {
    position: absolute;
    z-index: 2;

    height: 350px;
    top: 240px;
    left: 285px;
  }
  #imagec {
    position: absolute;

    height: 350px;
    top: 240px;
    left: 530px;
  }
  #pid {
    position: absolute;
    top: 148px;
    left: 100px;
  }
  #date {
    position: absolute;
    top: 150px;
    left: 272px;
  }
  #visit {
    position: absolute;
    top: 150px;
    left: 460px;
  }
  #WoundLocation {
    position: absolute;
    top: 150px;
    left: 550px;
    max-width: 200px;
    text-align: left;
  }
`;

const Container = styled.div`
position: relative;
font-size: 12px;
color: black;
span {
  font-weight : bold;
}
#hLogo{
  position: absolute;
  top: 35px;
  left: 28%;
  max-height: 45px;
  aspect-ratio: 1;
  object-fit: contain;
}
#hName{
  position: absolute;
  top: 35px;
  padding: 5px;
  left: 270px;
  width: 325px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;

}
img {
  height: 1080px ;
}
#gram {
  position: absolute;
  top: 655px;
  left: 125px;
  font-weight: 500;
}
#woundType {
  position : absolute;
  top: 165px;
  left: 460px
}
#len {
  position: absolute;
  top: 655px;
  left: 303px;
  font-weight: 500;
}
#wid {
  position: absolute;
  top: 655px;
  left: 470px;
  font-weight: 500;
}
#physicianName {
  position: absolute;
  top: 112px;
  left: 135px;
  font-weight: 500;
}
#sf_facility{
  position: absolute;
  top: 112px;
  left: 360px;
}
#area {
  position: absolute;
  top: 655px;
  left: 650px;
  font-weight: 500;
}
#image {
  position: absolute;
  height: 350px;
  top: 240px;
  left: 45px;
}
#laoder {
  position: absolute;
  top: 380px;
  left: 385px;
}
#imageb {
  position: absolute;
  z-index: 2;

  height: 350px;
  top: 240px;
  left: 285px;
}
#imagec {
  position: absolute;

  height: 350px;
  top: 240px;
  left: 530px;
}
#pid {
  position: absolute;
  top: 142px;
  left: 440px;
}
#date {
  position: absolute;
  top: 112px;
  left: 610px;
}
#visit {
  position: absolute;
  top: 143px;
  left: 660px;
}
#patientName {
  position: absolute;
  top: 142px;
  left: 180px;
}
#WoundLocation {
  position: absolute;
  top: 164px;
  left: 188px;
  max-width: 200px;
  text-align: left;
}
`;
export default Report;
