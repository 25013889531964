import React from "react";
import styled from "styled-components";
import ImageComponent from "../WoundDash/ImageComponent";
import { useLayoutEffect, useState, useEffect } from "react";
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);
const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

const VisitDashView = (props) => {
  

  const areaList = [];
  const visitList = [];
  const gramList = [];

  
  props.woundMetrics &&  Object.keys(props.woundMetrics).forEach((e) => {
    if (e !== 0) {
      areaList.push(
        props.woundMetrics[e].woundLength * props.woundMetrics[e].woundWidth
      );
      visitList.push(e);
      gramList.push(props.woundMetrics[e].gramType);
    }
  });
  const date =
  props.visitDataPoint ?  props.visitDataPoint["date"].slice(0, 2) +
    "-" +
    props.visitDataPoint["date"].slice(2, 4) +
    "-" +
    props.visitDataPoint["date"].slice(4, 8) : '';
  console.log(props.visitDataPoint && props.visitDataPoint["date"]);
  return (
    <div className="tab-content">
      {/* <div
        className="tab-empty"
        style={{ height: 500, display: "felx", flexDirection: "column" }}
      >
        <span>{props.PID}</span>
        <span>{props.buffer}</span>
        <span>{props.wlURL}</span>

        <span>{JSON.stringify(props.woundData)}</span>
        <span>{JSON.stringify(props.woundMetrics)}</span>
      </div> */}
      <Container>
        <Content>
          <MetaDiv>
            <h1>{props.PID}</h1>
            <h5>Visit {props.VID}</h5>
            {/* <span>Age: {props.woundData.age}</span>
            <span>Sex: {props.woundData.sex}</span>
            <span>Wound location: {props.woundData.woundLocation}</span>
            <span>Wound type: {props.woundData.woundType}</span> */}
         { props.visitDataPoint &&   <Table>
              {Object.keys(props.visitDataPoint).map((e) => {
                const result = e.replace(/([A-Z])/g, " $1");
                const finalResult =
                  result.charAt(0).toUpperCase() + result.slice(1);
                if (e === "date") {
                  return (
                    <TableRow key={e} >
                      <span>{finalResult}</span>
                      <span> {date}</span>
                    </TableRow>
                  );
                }
                return (
                  <TableRow key={e} >
                    <span>{finalResult}</span>
                    <span> {props.visitDataPoint[e]}</span>
                  </TableRow>
                );
              })}
            </Table>}
          </MetaDiv>
          <ImageDiv className="pic-a">
            <ImageComponent
              id={props.wlURL}
              url={props.wlURL}
              name={"White light"}
            />
          </ImageDiv>
        </Content>
      </Container>
    </div>
  );
};

const Container = styled.div``;
const Content = styled.div`
  padding: 12px;
  display: grid;
  grid-gap: 10px;
  /* grid-template-columns: 2fr 1fr; 
  grid-template-rows: 1fr 1fr;*/
  .pic-a {
    grid-column: 2;
    grid-row: 1;
  }
  .pic-b {
    grid-column: 3;
    grid-row: 1;
  }
`;
const MetaDiv = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  /* background-color: red; */
  margin-top: 32px;
  span {
    font-size: 16px;
  }
  .tag {
    color: #00d400;
  }
`;
const ImageDiv = styled.div`
  display: felx;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
`;
const GraphDiv = styled.div`
  grid-column: 1/4;
  grid-row: 2;
  /* background-color: black; */
  display: felx;
  justify-content: center;
  align-items: center;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;
const TableRow = styled.div`
  /* background-color: aqua; */
  padding: 9px 18px;
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  span {
    :nth-child(2) {
      /* margin-left: 100px; */
    }
  }
  &:nth-of-type(odd) {
    background: #e0e0e0;
  }
`;

export default VisitDashView;



// <GraphDiv>
//             <div
//               className="card"
//               style={{
//                 position: "relative",
//                 left: "0px",
//                 top: "0px",
//                 width: "95%",
//               }}
//             >
//               <div className="card-header ui-sortable-handle">
//                 <h3 className="card-title">
//                   <i className="fas fa-chart-line mr-1" />
//                   &ensp;Wound Area
//                 </h3>
//               </div>
//               {/* /.card-header */}
//               <div className="card-body">
//                 <div className="tab-content p-0">
//                   {/* Morris chart - Sales */}
//                   <div
//                     className="chart tab-pane active"
//                     id="revenue-chart"
//                     style={{
//                       position: "relative",
//                       height: "300px",
//                       // width: "100%",
//                     }}
//                   >
//                     <Plot
//                       divId="plotlyChart"
//                       style={{ height: "100%", width: "100%" }}
//                       data={[
//                         {
//                           x: visitList,
//                           y: areaList,
//                           mode: "lines+markers",
//                           type: "scatter",
//                           marker: { color: "red" },
//                           // line: { shape: "spline", smoothing: 1.3 },
//                           line: { shape: "spline", smoothing: 0.7 },
//                           fill: "tonexty",
//                           fillcolor: "#tonexty",
//                           hovertemplate:
//                           "<i>Visit No.</i>: %{x}" +
//                           "<br><i>Wound area: </i>: %{y:.2f}<br>" +
//                           "<extra></extra>",
//                         },
//                         // {
//                         //   // x: [0, 0],
//                         //   y: [0, 100],
//                         //   fill: "tozeroy",
//                         //   // type: "scatter",
//                         //   marker: { color: "red" },
//                         //   // line: { shape: "spline", smoothing: 1.3 },
//                         // },
//                         // {
//                         //   x: [2, 2],
//                         //   y: [0, 100],
//                         //   fill: "tozeroy",
//                         //   // type: "scatter",
//                         //   marker: { color: "red" },
//                         //   // line: { shape: "spline", smoothing: 1.3 },
//                         // },
                        
//                       ]}
//                       layout={{
//                         xaxis: {
//                           title: "Visit No.",
//                           // type: "date",
//                           fixedrange: true,
//                         },
//                         yaxis: {
//                           title: "Area Sq.",
//                           fixedrange: true,
//                           autosize: true,
//                         },
//                         // title: "House Prices vs Size",
//                       }}
//                       config={{ displaylogo: false, displayModeBar: false }}
//                     />
//                   </div>
//                 </div>
//               </div>
//               {/* /.card-body */}
//             </div>
//           </GraphDiv>