import React from "react";
import introGif from "../../asset/gif/login-page-1.gif";


const LoginView = (props) => {
  return (<>
  <div className="brand">
    <div className="logo-container">
      <img src="dist/img/adiuvoPro.png" alt="" height="40px" style={{marginTop:"-10px"}} />
    </div> 
    <div className="brand-name"><h1>Illuminate Pro</h1></div>
  </div>
    <div
      className="d-flex align-items-center justify-content-center"
      style={{
        flexDirection: "column",
        backgroundColor: "#0069d9",
        height: "100vh",
        width:"100%",
        backgroundImage:"linear-gradient( #70aae6 10% ,#3874b3 50%, #002a54)"
      }}
    >
      <div
        className="row align-items-center justify-content-center"
        style={{ backgroundColor: "transparent", borderRadius: "40px ",
        width:"100%" ,
        maxWidth:"800px",
        margin:"1%" }}
      >
        <div
          className="hold-transition login-page col-12 col-sm-6"
          style={{ backgroundColor: "transparent", height: "auto" , borderRadius: "35px", padding:"8px"}}
        >
          <div>
            <img
              src="dist/img/adiuvoPro.png"
              alt="illuminate"
              style={{
                height: "42px",
                position: "fixed",
                bottom: "12px",
                right: "12px",
                backgroundColor: "#081425",
                padding: "6px",
              }}
            />
          </div>
          <div
            className="login-box"
            style={{ margin: "1%", borderRadius: "25px" }}
          >
            
            <div>
              <div
                className="card-body login-card-body"
                style={{
                  backgroundColor:"transparent",
                  borderRadius: "30px",
                  padding: "5%",
                  width: "100%",
                  maxWidth: "600px",
                  color: "white"
                }}
              >
                <h3 style={{textAlign:"center"}} >Log In</h3>
                <p className="login-box-msg">Sign in to start your session</p>
                <form action="../../index3.html" method="post">
                  <div className="input-group mb-3">
                    <input
                      style={{
                        height: "55px",
                        borderRadius: "25px",
                        border: "1px",
                        fontSize: "16px",
                      }}
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => {
                        e.preventDefault();
                        props.setEmail(e.target.value);
                      }}
                    />
                    {/* <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div> */}
                  </div>
                  <div className="input-group mb-3">
                    <input
                      style={{
                        height: "55px",
                        borderRadius: "25px",
                        border: "1px",
                        fontSize: "16px",
                      }}
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => {
                        e.preventDefault();
                        props.setPassword(e.target.value);
                      }}
                    />
                    {/* <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="icheck-primary">
                        <input
                          type="checkbox"
                          id="remember"
                          onClick={(e) => {
                            props.onSetPersistence(e.target.checked);
                          }}
                        />
                        <label htmlFor="remember">Remember Me</label>
                      </div>
                    </div>
                    <div className="col-4">
                      
                        <button
                          disabled={props.isFetching}
                          style={{
                            borderRadius: "25px",
                            border: "1px",
                            fontSize: "20px",
                            padding: "5px",
                          }}
                          type="submit"
                          className="btn btn-primary btn-block"
                          onClick={(e) => {
                            e.preventDefault();
                            props.onLogin();
                          }}
                        >
                          { props.isFetching  ? "Loading... " : "Sign In"}
                        </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-12 col-md-6 d-none d-md-block">
          <img
            src={introGif}
            alt="Summary"
            style={{ maxWidth: "99%", maxHeight: "55vh", borderRadius: "25px"}}
          />
        </div> */}
      </div>
      <div>

                     


</div>
  
    </div>
    <div style={{
      position:"fixed",
      bottom: 0,
      zIndex: 21,
      left:0,
      width:"100%"
      }} >
           <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
<defs>
<path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
</defs>
<g className="parallax">
<use xlinkHref="#gentle-wave" x="48" y="0" fill="#fff" fillOpacity="0.2" />
<use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.3)" />
<use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.4)" />
<use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" fillOpacity="0.5"/>
</g>
</svg>
            </div> </>
  );
};

export default LoginView;
