import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import DataStore from "services/facade/dataStore";
const initialState = {
  userName: "",
  patients: null,
  isFetching: false,
  isInnerFetching: null,
  isError: false,
  generalUserAnalytics: {
    value: null,
    isFetching: false,
    isError: false,
  },
  visitData: {
    value: null,
    isFetching: false,
    isError: false,
  },
};

const mDataStore = new DataStore();

export const getUserName = createAsyncThunk("data/get-user-name", () => {
  return new Promise((resolve, reject) => {
    mDataStore.getUserName().then((userName) => {
      resolve(userName);
    });
  });
});

export const getGeneralUserAnaltycs = createAsyncThunk("data/get-gua", () => {
  return new Promise((resolve, reject) => {
    mDataStore.getGeneralUserAnaltycs().then((gua) => {
      resolve(gua);
    });
  });
});

export const getVisitData = createAsyncThunk(
  "data/get-visit-data",
  ({ PID, VID }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore.getVisitData(PID, VID).then((data) => {
        resolve(data);
      });
    });
  }
);

export const listPatientData = createAsyncThunk("data/list-patients", () => {
  return new Promise((resolve, reject) => {
    mDataStore
      .listPatients()
      .then((data) => {
        console.log(data);
        resolve(data);
      })
      .catch(() => {
        reject();
      });
  });
});

export const listVisitData = createAsyncThunk(
  "data/list-visit",
  (props, thunkAPI) => {
    // console.log(props);
    return new Promise((resolve, reject) => {
      mDataStore
        .listVisits(props.name)
        .then((data) => {
          resolve(data);
          // console.log(data);
        })
        .catch(() => reject());
    });
  }
);

export const dataSlice = createSlice({
  name: "dataState",
  initialState,
  reducers: {
    pushNewImage: (state, payload) => {
      console.log(payload);
    },
  },
  extraReducers: {
    [listPatientData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
    },
    [listPatientData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.patients = payload;
    },
    [listPatientData.rejected]: (state) => {
      state.isError = true;
      state.isFetching = false;
    },
    [listVisitData.pending]: (state) => {
      state.isInnerFetching = true;
    },
    [listVisitData.fulfilled]: (state, { payload }) => {
      state.isInnerFetching = false;
      state.patients[payload.name] = payload.res;
    },
    [listVisitData.rejected]: (state) => {
      // state.isInnerFetching = true;
    },
    [getUserName.pending]: (state) => {},
    [getUserName.fulfilled]: (state, { payload }) => {
      state.userName = payload;
    },

    [getGeneralUserAnaltycs.pending]: (state) => {
      // state.generalUserAnaltyce.value = payload;
    },
    [getGeneralUserAnaltycs.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.generalUserAnalytics.value = payload;
    },
    [getVisitData.pending]: (state) => {
      // state.generalUserAnaltyce.value = payload;
    },
    [getVisitData.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.visitData.value = payload;
    },
  },
});

export const selectDataState = (state) => state.dataState;

export const { pushNewImage } = dataSlice.actions;

export default dataSlice.reducer;
