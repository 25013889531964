import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataStore from "services/facade/dataStore";

const mDataStore = new DataStore();

const initialState = {
  data: {},
  isFetching: false,
  isError: false,
};

export const fetchVisitDataPoint = createAsyncThunk(
  "data/fetchVisitDataPoint",
  ({ PID, VID }, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore
        .getVisitDataPoint(PID, VID)
        .then((data) => {
          resolve({ VID: PID + "/" + VID, data });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

export const fetchImageAndURL = createAsyncThunk(
  "data/fetchImageAndURL",
  ({ PID, VID, searchStr , dateStr}, thunkAPI) => {
    return new Promise((resolve, reject) => {
      mDataStore
        .fetchImagePath(PID, VID, searchStr, dateStr)
        .then((path) => {
          console.log(path, "path");
          if(!path){
            mDataStore.fetchImagePathFromProcessed(PID, VID, searchStr, dateStr).then((path) => {
              mDataStore.fetchImageURL(path).then((url) => {
                resolve({ VID: PID + "/" + VID, url, searchStr , dateStr});
              });
            });
          }
          mDataStore.fetchImageURL(path).then((url) => {
            resolve({ VID: PID + "/" + VID, url, searchStr , dateStr});
          });
        })
        .catch((err) => {
          reject();
        });
    });
  }
);

const visitDashSlice = createSlice({
  name: "visitDashState",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVisitDataPoint.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchVisitDataPoint.rejected]: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    [fetchVisitDataPoint.fulfilled]: (state, { payload }) => {
      state.data[payload.VID] = state.data[payload.VID]
        ? state.data[payload.VID]
        : {};
      state.data[payload.VID]["visitDataPoint"] = payload.data;
      state.isLoading = false;
      state.isError = false;
    },
    [fetchImageAndURL.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchImageAndURL.rejected]: (state) => {
      state.isError = true;
    },
    [fetchImageAndURL.fulfilled]: (state, { payload }) => {
      //   state.imageWL.path = payload.path;
      //   state.imageWL.url = payload.url;
      state.data[payload.VID] = state.data[payload.VID]
        ? state.data[payload.VID]
        : {};
      state.data[payload.VID][payload.searchStr] = payload.url.url;
      console.log("setting", payload.VID, state.data);

      state.isFetching = false;
      state.isError = false;
    },
  },
});

export const selectVisitDashState = (state) => state.visitDashState;

export default visitDashSlice.reducer;
