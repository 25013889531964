import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  PID: null,
  VID: 0,
  tabs: [],
  currentTab: -2,
};

const dashboardSlice = createSlice({
  name: "dashboardState",
  initialState,
  reducers: {
    populateDash: (state, { payload }) => {
      state.tabs = payload.data;
      state.PID = payload.PID;
      state.currentTab = 0;
      console.log(state.tabs);
    },
    createNewTab: (state, { payload }) => {
      if (!state.tabs.includes(payload)) {
        state.tabs.push(payload);
        state.currentTab = state.tabs.length - 1;
      } else {
        state.currentTab = state.tabs.indexOf(payload);
      }
      console.log(state.tabs);
    },
    changeCurrentTab: (state, { payload }) => {
      console.log(payload)
      state.currentTab = payload;
      if (payload < 0) {
        state.tabs = [];
        state.PID = null;
      }
      if (payload >= 0) {
        state.VID = payload;
      }
    },
    closeTab: (state, { payload }) => {
      state.tabs.splice(payload, 1);
      if (state.currentTab >= state.tabs.length)
        state.currentTab = state.tabs.length - 1;
    },
    closeAllTabs: (state) => {
      state.tabs = [];
      state.currentTab = -1;
    },
    closeAllTabsExcept: (state, { payload }) => {
      state.tabs = [state.tabs[payload]];
      state.currentTab = 0;
    },
    moveTab: (state, { payload }) => {
      if (payload === -1 && state.currentTab > 0)
        state.currentTab = state.currentTab - 1;
      if (payload === 1 && state.currentTab < state.tabs.length - 1)
        state.currentTab = state.currentTab + 1;
    },
  },
  extraReducers: {},
});

export const selectDashboardState = (state) => state.dashboardState;

export const {
  populateDash,
  createNewTab,
  changeCurrentTab,
  closeTab,
  closeAllTabs,
  closeAllTabsExcept,
  moveTab,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
