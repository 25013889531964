import { AndroidOutlined, AndroidTwoTone, ArrowDropDown, ArrowDropDownCircleOutlined, ArrowDropUp, ArrowDropUpOutlined, ArrowUpward, Camera, CameraEnhanceOutlined, Cancel, CancelOutlined, DeviceHubOutlined, Edit, EngineeringOutlined, HardwareOutlined, HardwareTwoTone, IntegrationInstructionsRounded, PhoneAndroidRounded, PhotoCamera } from '@mui/icons-material'
import { Alert, Avatar, Badge, Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material'
import LinearProgressBar from 'components/elements/LinearProgressBar'
import Loader from 'components/loader/loader'
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'
import { update } from 'plotly.js'
import React, { useEffect, useState } from 'react'
import { firebaseGenerateDownloadURL, firebaseGetDeviceDetail, firebaseGetImagePath, firebaseGetMissingData, firebaseGetUserConfig, getOrInitApp, getUID, updateUserData } from 'services/firebase/firebaseStorage'
import firmwareIcon from '../../asset/icons/firmware_icon.png'
import hardwareIcon from '../../asset/icons/hardware_icon.png'
import ramIcon from '../../asset/icons/ram_icon.png'
import stackIcon from '../../asset/icons/stack_icon.png'

function SettingsContainer({userInfo}) {
    const [aboutDevice, setAboutDevice] = useState(null)
    const [alertMsg, setAlertMsg] = useState(null)
    const [isLoading, setLoading] = useState(true)
    const[showAllDetails, setshowAllDetails] = useState(false)
    const[userConfig, setUserConfig] = useState(null)

    const [isUserConfigEditting, setUserConfigEdit] = useState(false)
    const [newUserconfig, setNewUserConfig] = useState({userName : ""})
    const[hospitalLogoUrl, setHospitalLogoUrl] = useState(null)
    const [progress, setProgress] = useState(0);

    const [missingData, setMissingData] = useState(null)


    useEffect(() => {
      firebaseGetUserConfig().then(data=>{
        console.log(data)
        setUserConfig(data)
        setNewUserConfig(data)
      }).catch(err=>{
        console.error(err)
        setAlertMsg(err)
      })
      firebaseGetDeviceDetail().then(data=>{
        console.log(data);
        setAboutDevice(data)
      }).catch(err=>{
        console.error(err);
        setAlertMsg(err)
      })

      firebaseGetMissingData().then(data=>{
        console.log(data)
        setMissingData(data)
    }).catch(err=>{
        console.error(err)
    })
    firebaseGenerateDownloadURL(`hospitals/${getUID()}/profiles/hospitalLogo`).then(url=> { 
       setHospitalLogoUrl(url)})
    }, [])

    useEffect(()=>{
      if(aboutDevice){
         setLoading(false)
        }
    },[aboutDevice])

    const handleUpload = e => {
      const file = e.target.files[0];
      const storage = getStorage(getOrInitApp());
      const storageRef = ref(storage, `hospitals/${getUID()}/profiles/hospitalLogo`);
      const task = uploadBytesResumable(storageRef, file);
    
      task.on(
        'state_changed',
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          setProgress(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        error => {
          // Handle unsuccessful uploads
          setAlertMsg(error);
        },
        () => { 
          getDownloadURL(task.snapshot.ref).then(url=>{
            console.log(url)
            setHospitalLogoUrl(url)});
        }
      );
    };
    
  return (
  <>
    { isLoading ?  <div className='loader-container' >
        <Loader width={300} height={300} />
      </div> : 
    <div style={{
      height:"75vh",
      padding:"2%",
      position:"relative"
    }}>
      {alertMsg && <Alert title='Error'  severity='error'>{alertMsg}</Alert> }

      {/* Profile Details */}

     { userConfig && <div>

        <h3>Profile Details</h3>
        <div className="d-flex align-items-center mx-auto" style={{borderRadius: "41px", background:"#fafafa" , width:"fit-content"}}>
          <div className='mx-5' >
             <Badge
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
             overlap="circular" badgeContent={
             <IconButton
             aria-label="upload picture" component="label" 
              variant='contained'
              style={{backgroundColor:"white"}} 
              color="inherit"
              > <input hidden accept="image/*" type="file"  onChange={handleUpload} />
              <PhotoCamera /></IconButton>} >  <Avatar src={hospitalLogoUrl} style={{width:"120px", height:"120px", border:"3px white solid",padding:"2px"}} imgProps={{style:{aspectRatio:"1", objectFit:"contain"}}} /> </Badge>
           
          </div>
          <div className='p-5'>
            <div>
             { !isUserConfigEditting ? <> 
             <p className='mx-1'>Display Name : <span>{userConfig.userName}</span></p>
             <p className='mx-2'> Prefix Id : <span>{userConfig.idPrefix}</span> </p>
              <Button
                startIcon={<Edit/>}
                variant="outlined"
                color='inherit'
                onClick={()=>{
                  setUserConfigEdit(true)
                }}
              >
                Edit
              </Button> </> 
              : 
              <div>

                <TextField
                margin='normal'
                label="Display Name"
                  value={newUserconfig.userName}
                  onChange={(e)=>{
                    setNewUserConfig({...userConfig, userName : e.target.value})
                  }}
                />
                 <br/>
                <TextField
                margin='normal'
                label="Hospital Name"
                value={newUserconfig.hospitalName}
                onChange={(e)=>{
                  setNewUserConfig({...userConfig, hospitalName : e.target.value})
                }}
                />
                <br/>
                <TextField
                  margin='normal'
                  label={userConfig.idPrefix}
                  disabled
                />

                <br/>
                {/* {
                  userConfig.userName === newUserconfig.userName ? <Button
                    color='error'
                    onClick={()=>{
                      setUserConfigEdit(false)
                    }}
                  >
                    cancel
                  </Button> :  */}
                  <>
                    <IconButton
                    color='error'
                    onClick={()=>{
                      setUserConfigEdit(false)
                    }}
                  >
                    <CancelOutlined />
                  </IconButton>
                    <Button
                      color="primary"
                      onClick={()=>{
                        updateUserData(newUserconfig).then(reponse=>{
                          setUserConfigEdit(false)
                          // reload comes here
                        }).catch(err=>{
                          console.error(err)
                        })
                      }}
                    >
                      Save
                    </Button>
                  </>
                {/* } */}
              </div>}
            </div>
            {/* <div className="d-flex">
              <p className='mx-2'> Prefix Id : <span>{userConfig.idPrefix}</span> </p>
            </div> */}
          </div>
        </div>
        

      </div>}

    

{/* Device Details */}
      <div className="d-flex align-items-center">
        <h3>Device Details</h3>
        <IconButton
          // endIcon={!showAllDetails ? <ArrowDropDownCircleOutlined/> : <ArrowDropUpOutlined/>}
          style={{width:"fit-content",margin:"2px"}}
          onClick={()=>{
            setshowAllDetails(!showAllDetails)
          }}
        >
         {!showAllDetails ? <ArrowDropDownCircleOutlined/> : <ArrowDropUpOutlined/>}
        </IconButton>
      </div>

      {!!showAllDetails && <div className='row justify-content-center align-items-center'>
       <Table size="small">
          <TableBody>
            {Object.keys(aboutDevice).map(key=><TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{aboutDevice[key]}</TableCell>
            </TableRow>)}
          </TableBody>
       </Table>
      </div>}
      <div className='row w-100 justify-content-around align-items-center'>
          {aboutDevice.appVersion&&<div className='col-12 p-2 col-sm-6 col-md-3 justify-content-around align-items-center text-center'  title="Device Software Version" >
            <AndroidOutlined style={{fontSize:"50px", color:"#30dc80"}}/><br></br>
            <small>App Version</small>
            <p>{aboutDevice.appVersion}</p>
          </div>}
          {aboutDevice.chassisVersion&&<div className='col-12 p-2 col-sm-6 col-md-3  text-center' title='Chassis Version of Device' >
            <PhoneAndroidRounded style={{fontSize:"50px"}}/><br></br>
            <small>Chassis Version</small>
            <p>{aboutDevice.chassisVersion}</p>
          </div>}
{aboutDevice.firmwareId !== "na" && <div title="firmware Id" className='col-12 col-sm-6 p-2  col-md-3 justify-content-center align-items-center text-center' >
            <img alt="firmwareIcon" src={firmwareIcon} style={{width:"50px"}}/>
            <br></br>
            <small>Firmware ID</small>
            <p>{aboutDevice.firmwareId}</p>
          </div>}

    {aboutDevice.hardwareVersion !== 'na' &&  <div title='Hardware Version' className='col-12 col-sm-6 p-2  col-md-3 justify-content-center align-items-center text-center' >
            <img src={hardwareIcon} alt="hardware icon" style={{width:"50px"}}/>
            <br></br>
            <small>Haedware Version</small>
            <p>{aboutDevice.hardwareVersion}</p>
          </div>}

          {aboutDevice.ram&&aboutDevice.ram !== "na" && <div title="Ram Size" className='col-12 col-sm-6 p-2  col-md-3 justify-content-center align-items-center text-center' >
            <img alt="Ram icon" src={ramIcon} style={{width:"50px"}}/>
            <br></br>
            <small>RAM</small>
            <p>{(aboutDevice.ramAvailable/1000000).toFixed(2)} GB / {(aboutDevice.ram/1000000).toFixed(2)} GB</p>
          </div>}

          {aboutDevice.storageAvailable&& aboutDevice.storageAvailable!=="na"&&aboutDevice.storageTotal !== "na" && <div title="Storage Size" className='col-12 p-2 col-sm-6 col-md-3 justify-content-center align-items-center text-center' >
            <img alt="Ram icon" src={stackIcon} style={{width:"50px"}}/>
            <br></br>
            <small>Device Storage</small>
            <p>{parseInt(aboutDevice.storageAvailable.split("M")[0].split(",").join(''))/1000} GB / {(parseInt(aboutDevice.storageTotal.split("M")[0].split(",").join(''))/1000).toFixed(2)} GB</p>
          </div>}

          
      </div>
      
    </div>}

    {/* Missing Datas */}

    {
      missingData &&
      missingData.NumberOfMissingImages > 0 && 
<div className='p-4'>
      <h3>Missing Patient Datas</h3>
      <Alert severity='warning' >These are the missing data in cloud and you need to upload them individually from device.</Alert>
        <Table>
  
              <TableHead>
                  <TableRow>
                      <TableCell>Patient Id</TableCell>
                      <TableCell>Visit No.</TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                  {
                      Object.keys(missingData.MissingImages).map(pid=><TableRow key={pid}>
                          <TableCell>{`${pid.split("_")[0]}_${pid.split("_")[1]}`}</TableCell>
                          <TableCell>{pid.split("_").at(-1)}</TableCell>
                      </TableRow>)
                  }
              </TableBody>
          </Table>
</div>
    }
    </>
  )
}

export default SettingsContainer