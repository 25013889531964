import React from "react";/* eslint-disable jsx-a11y/anchor-is-valid */
import { Close, Search, Settings, SettingsOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ProfileSetting from "pages/profieSetting";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectDashboardState } from "redux/dashboardSlice";
import { selectWoundDashState } from "redux/woundDashSlice";
import { getUID } from "services/firebase/firebaseStorage";

const SidebarView = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const { woundDataPoint, woundMetrics, imageWL, data } = useSelector(selectWoundDashState);
  const { PID, tabs } = useSelector(selectDashboardState);
  console.log(props.patientList);
  console.log(data);
  // console.log(tabs)
  const [patientList, setPatientList] = useState(null);
  const history = useHistory()


  useEffect(()=>{
    let arr = []
  },[])

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  return (
    <aside className="main-sidebar sidebar-light-primary" style={{backgroundColor:"#f4f6f9", position:"fixed"}}>
        
        <a href="" data-widget="pushmenu" className="brand-link" style={{ height: "72px" }}>
          <img
            src="dist/img/dark-logo.png"
            alt=""
            className="brand-image"
            style={{ opacity: ".8", width: "80%", "marginTop": "12px" }}
          />
        </a>
        
          <div className="user-panel mt-3 pb-3 mb-3 d-flex justify-content-between align-items-center">
            <div className="info">
              <span className="d-block" style={{ color: "black" }}>
                {props.userName}
              </span>
              <a
                className="d-block"
                style={{ cursor: "pointer", fontSize: "12px"}}
                onClick={() => {
                  props.onLogout();
                }}
              >
                Logout
              </a>
            </div>
            <div style={{padding:"5px 15px"}} >
              <ProfileSetting userInfo={{userName : props.userName, logout : props.onLogout}} />
            </div>
          </div>
         
          <div className="sidebar">
          <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input
                className="form-control form-control-sidebar"
                type="search"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar" 
                  onClick={()=>{setSearchQuery("")}}
                >
                  {searchQuery.length > 0 ? <Close fontSize="small" /> : <Search fontSize="small" />}
                </button>
              </div>
            </div>
          </div>
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              className="nav-item"
              onClick={() => {
                setSelectedPatient("");
                props.onHomeClick();
              }}
              style={{
                cursor: "pointer",
                marginTop: "12px",
              }}
            >
              <a className={`nav-link ${props.currentTab === -2 && "active"}`}>
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </a>
            </li>
            <li className="nav-header">PATIENTS</li>
            <Scrollbars style={{ height: "60vh" }}>
              {/* (a,b)=> a.split("_").at(-1)-b.split("_").at(-1) */}
              {
              // getUID()=== "Cnt0GwQcBOOBEBTg5co5afeb2PC3" ? 
              Object.keys(props.patientList).sort((a,b)=> a.split("_").at(-1)-b.split("_").at(-1)).map((e) => {
                if (e.includes(searchQuery.toUpperCase()) || props.patientList[e].toUpperCase().includes(searchQuery.toUpperCase()))
                  return (
                    <li
                    className="nav-item"
                      onClick={() => {
                        setSelectedPatient(e);
                        props.onClickHandler(e);
                      }}
                      style={{
                        cursor: "pointer"
                      }}
                      key={e}
                    >
                      <a className={`nav-link ${e === selectedPatient && "active"}`}>
                        <span className="d-flex">
                        <p>{ e === selectedPatient?  <i className="fas fa-circle nav-icon" style={{color:"inherit"}}></i> : <i className="far fa-circle nav-icon"></i>}</p>
                        <p style={{padding:" 0 5px"}}>{" "}{props.patientList[e]} </p>
                        <span style={{fontSize:"x-small", opacity:"0.5", marginLeft:"auto", marginRight:"0"}}>{e}</span> 
                        </span>
                   
                      </a>
                    </li>
                  );
              }) 
              // :
              // props.patientList.sort((a,b)=> a.split("_").at(-1)-b.split("_").at(-1)).map((e) => {
              //   if (e.includes(searchQuery))
              //     return (
              //       <li
              //       className="nav-item"
              //         onClick={() => {
              //           setSelectedPatient(e);
              //           props.onClickHandler(e);
              //         }}
              //         style={{
              //           cursor: "pointer",
              //         }}
              //       >
              //         <a className={`nav-link ${e === selectedPatient && "active"}`}>
              //        { e === selectedPatient?  <i className="fas fa-circle nav-icon" style={{color:"inherit"}}></i> : <i className="far fa-circle nav-icon"></i>}
              //           <p>{" "}{e}</p>
              //         </a>
              //       </li>
              //     );
              // })
          }</Scrollbars>
          </ul>
          {/* <Patients searchQuery={searchQuery} /> */}
        </div>
    
    </aside>
  );
};

export default SidebarView;
