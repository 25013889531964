import React from "react";
const { default: VisitDash } = require("components/VisitDash")
const { default: WoundDash } = require("components/WoundDash")

const Frame = (props) =>{
  console.log(props.currentTab)
    return   <div className="tab-content" style={{marginBottom : "200px",
    paddingBottom:"50px"}}>
    {/* <ImageDashboard path={props.tabs[props.currentTab]} /> */}
    {props.currentTab.split("/").at(1) === "0" ? (
      <WoundDash />
    ) : (
      <VisitDash />
    )}
  </div>
}

export default Frame