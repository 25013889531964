import React from "react";
import { Switch, Route } from "react-router-dom";

import { ROUTES } from "./contants";
import SplashScreen from "pages/Splash";
import LoginScreen from "pages/Login";
// import SettingsContainer from "pages/profieSetting/settingsContainer";

const RouterConfig = () => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN}>
        <LoginScreen />
      </Route>
      
      {/* <Route path={ROUTES.PROFILE}>
        <SettingsContainer />
      </Route> */}

      <Route path={ROUTES.ROOT}>
        <SplashScreen />
      </Route>
    </Switch>
  );
};

export default RouterConfig;
