import React from "react";/* eslint-disable jsx-a11y/anchor-is-valid */
const NavbarView = (props) => {
  return (
    <nav className="main-header navbar navbar-expand-md navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
        <li
          className="nav-item d-none d-sm-inline-block"
          onClick={props.onHomeClick}
          style={{ cursor: "pointer" }}
        >
          <a className={`nav-link ${props.currentTab === -2 && "active"}`}>
            Home
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="" className="nav-link">
            Contact
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        {/* <li className="nav-item">
          <a className="nav-link" data-widget="navbar-search" role="button">
            <i className="fas fa-search" />
          </a>
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <i className="fas fa-search" />
                  </button>
                  <button
                    className="btn btn-navbar"
                    type="button"
                    data-widget="navbar-search"
                  >
                    <i className="fas fa-times" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li> */}
        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt" />
          </a>
        </li>

        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" role="button">
            <i className="fa fa-user" aria-hidden="true" />
            {/* <span className="badge badge-warning navbar-badge">15</span> */}
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            <span className="dropdown-item dropdown-header">
              {props.userName}
            </span>
            <div className="dropdown-divider" />
            <a
              role="button"
              className="dropdown-item"
              onClick={(e) => {
                e.preventDefault();
                props.onLogout();
              }}
            >
              <i className="fa fa-sign-out-alt" aria-hidden="true" /> Logout
            </a>

            <div className="dropdown-divider" />
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarView;
