import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import dataStoreReducer from "./dataSlice";
import dashboardReducer from "./dashboardSlice";
import imageReducer from "./imageSlice";
import mainDashReducer from "./mainDashSlice";
import woundDashReducer from "./woundDashSlice";
import visitDashReducer from "./visitDashSlice";

export const store = configureStore({
  reducer: {
    authState: authReducer,
    dataState: dataStoreReducer,
    dashboardState: dashboardReducer,
    imageState: imageReducer,
    mainDashState: mainDashReducer,
    woundDashState: woundDashReducer,
    visitDashState: visitDashReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload.match"],
        ignoredPaths: ["authState.authError.match"],
      },
    }),
});

export const dispatch = (action) => {
  store.dispatch(action);
};
